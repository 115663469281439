<template>
  <div id="profile-container">
    <div id="profile">
      <div id="profile-title">
        My Account
      </div>
      <div id="profile-sidebar">
        <div id="selectors">
          <div class="nav-item active">
            <i class="fal fa-user-circle"></i> My details
          </div>
          <div class="nav-item">
            <i class="fal fa-map-marker-alt"></i> My address book
          </div>
          <div class="nav-item">
            <i class="fal fa-bags-shopping"></i> My orders
          </div>
          <div class="nav-item">
            <i class="fal fa-cog"></i> Account settings
          </div>

        </div>
      </div>
      <div id="profile-info">
        <div id="info-title">
          <p>My details</p>
        </div>
        <div class="info-block">
          <div class="info-block-title">
            <p>Personal information</p>
            <hr/>
          </div>
          <div class="info-block-content">
            <div class="input-row">
              <div class="input-field">
                <p>FIRST NAME</p>
                <input type="text"/>
              </div>
              <div class="input-field">
                <p>LAST NAME</p>
                <input type="text"/>
              </div>
            </div>
            <div class="input-row">
              <div class="input-field">
                <p>Birth date</p>
                <input type="date"/>
              </div>
            </div>
            <div class="input-row">
              <div class="input-field">
                <p>Phone number</p>
                <input type="date"/>
              </div>
            </div>

            <button type="button">Save</button>
          </div>
        </div>


        <div class="info-block">
          <div class="info-block-title">
            <p>E-mail Address</p>
            <hr/>
          </div>
          <div class="info-block-content">
            <div class="input-row">
              <div class="input-field">
                <p>Email address</p>
                <input type="text"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import Subscription from "@/components/Subscription.vue";
import LootBox from "@/components/LootBox.vue";

export default defineComponent({
  name: 'Profile',
  components: {LootBox, Subscription},
});
</script>


<style lang="scss" scoped>

</style>