<template>
  <div class="basket-item">
    <div class="basket-item-image">
      <img :src="itemImageURL" alt="no"/>
    </div>
    <div class="basket-item-name">
      {{ itemName }}
    </div>

    <div class="basket-item-amount">
      <p>Amount: &nbsp;</p>
      <input type="number" :value="itemsAmount">
    </div>

    <div class="basket-item-price">
      <p>€{{ price }}</p>
    </div>

  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BasketItem',
  props: {
    itemName: {
      type: String,
      required: true
    },
    itemImageURL: {
      type: String,
      required: true
    },
    itemsAmount: {
      type: Number,
      required: true
    },
    price: {
      type: Number,
      required: true
    },
  },
});
</script>

<style scoped lang="scss">

</style>
