<template>
  <div id="basket-container">
    <div id="basket">
      <div id="basket-title">
        My Basket
      </div>
      <div id="basket-info">
        <basket-item item-name="Obsidian box" :price="50" item-image-u-r-l="https://cdn.discordapp.com/attachments/483709686655483915/849231983027552266/Obsidian_box.png" :items-amount="2"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import Subscription from "@/components/Subscription.vue";
import LootBox from "@/components/LootBox.vue";
import BasketItem from "@/components/BasketItem.vue";

export default defineComponent({
  name: 'Basket',
  components: {BasketItem, LootBox, Subscription},
});
</script>


<style lang="scss" scoped>

</style>