<template>
  <div class="subscription-container">
    <div class="subscription">
      <div class="subscription-image">
         <span class="fa-stack">
           <i class="fas fa-shield fa-stack-2x"  :style="colorStyle"></i>
           <i class="fas fa-stack-1x fa-inverse" :class="icon"></i>
         </span>
      </div>
      <div id="title">
        {{ name }}
      </div>
      <div class="subscription-perks">
        <p class="subscription-perk">+ A <i class="colored" :style="colorStyle">[{{ name }}]</i> CHAT PREFIX</p>
        <p class="subscription-perk">+ A <i class="colored" :style="colorStyle">{{ name }}</i> COLORED NAME</p>
        <p class="subscription-perk">+ EXP <i class="yellow">{{ expBoost }}X</i></p>
        <p class="subscription-perk">+ {{ vanityItemsAmount }} SPECIAL <i class="yellow">VANITY</i> ITEMS</p>
      </div>
      <button class="buy-button" type="button">BUY NOW</button>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'Subscription',
  components: {},
  props: {
    name: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    expBoost: {
      type: Number,
      required: true
    },
    vanityItemsAmount: {
      type: Number,
      required: true
    },
  },
  computed: {
    colorStyle() {
      return {
        "color": this.color
      }
    }
  }
});
</script>


<style lang="scss" scoped>
.subscription-container {
  width: 20%;
  display: flex;
  justify-content: center;
  .subscription {
    display: flex;
    align-items: center;
    flex-direction: column;

    .subscription-image {
      width: 220px;
      height: 220px;
      border-radius: 100%;
      background-color: #fcfcfc;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      .fa-stack{
        font-size: 4.3em;
        //.fa-stack-2x {
        //  color: blue;
        //}
      }
    }

    #title {
      font-family: "Bebas Neue", cursive;
      color: white;
      font-size: 2em;
    }

    .subscription-perks {
      color: white;

      .subscription-perk {
        font-family: "Bebas Neue", cursive;
        //letter-spacing: 0.1em;
        font-size: 1.5em;

        .yellow {
          font-style: normal;
          color: #f5b41a;
        }

        .colored {
          font-style: normal;
        }
      }
    }

    .buy-button{
      margin-top: 20px;
      align-self: start;
      width: 50%;
      border: none;
      color: #143d59;
      font-family: "Bebas Neue", cursive;
      font-size: 2em;
    }
  }
}
</style>