
import {defineComponent} from 'vue';
import Subscription from "@/components/Subscription.vue";
import LootBox from "@/components/LootBox.vue";

export default defineComponent({
  name: 'Home',
  components: {LootBox, Subscription},
  data() {
    return {
      modalOpen: false
    }
  }
});
