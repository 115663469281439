
import {defineComponent} from 'vue';
import Subscription from "@/components/Subscription.vue";
import LootBox from "@/components/LootBox.vue";
import BasketItem from "@/components/BasketItem.vue";

export default defineComponent({
  name: 'Basket',
  components: {BasketItem, LootBox, Subscription},
});
