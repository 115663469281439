<template>
  <div id="nav">
    <div id="header-left">
<!--      <i class="far fa-bars"></i>-->
    </div>
    <router-link to="/">
      <img src="./assets/Mistal-light@0.5x.png" alt="Mistral Logo">
    </router-link>
    <div id="header-right">
      <router-link to="/profile">
      <i class="far fa-user"></i>
      </router-link>
      <router-link to="/basket">
        <i class="far fa-shopping-basket"></i>
      </router-link>
    </div>
  </div>
  <router-view/>
</template>

<style lang="scss">
* {
  box-sizing: border-box;
  margin: 0;
}

a {
  color: #282627;
}

html {
  background-color: #f3f3f3;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#app {
  color: #282627;
  //background-color: #fcfcfc;
  //background-color: #f3f3f3;
  font-family: "work-sans", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Bebas Neue", cursive;
  //letter-spacing: 0.08em;
  font-weight: normal;
}

h1 {
  font-size: 8em;
}

h2 {
  font-size: 5em;
}

#nav {
  font-size: 1.5em;
  padding: 8px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  background-color: #fcfcfc;
  z-index: 9999;

  #header-left, #header-right {
    width: 120px;
  }

  img {
    height: 60px;
  }

  #header-right {
    display: flex;
    justify-content: space-around;
  }
}
</style>
