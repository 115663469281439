<template>

  <div id="header">
    <div id="header-overlay">
      <div id="header-title">
        <h1 id="title">MISTRAL NETWORK Shop</h1>
        <p id="subtitle">A network fo the players, by the players!</p>
      </div>
    </div>
  </div>

  <div id="info-block">
    <div class="block">
      <div class="block-inner">
        <h2>Mistral Network</h2>
        <p>Welcome to Mistral Network shop! The place to be for all your
          <br /> in-game goodies and perks! Check the items below and make your choice!</p>
      </div>
    </div>
    <div class="block">
      <img src="../assets/header.jpg" alt="image">
    </div>
  </div>

  <div id="subscriptions">
    <h2 id="subscriptions-title">subscriptions</h2>
    <subscription icon="fa-helmet-battle" name="Netherite" color="#4e494d" :exp-boost="4.0" :vanity-items-amount="40"/>
    <subscription icon="fa-axe-battle" name="Diamond" color="#32b7dd" :exp-boost="4.0" :vanity-items-amount="40"/>
    <subscription icon="fa-swords" name="Emerald" color="#4aba58" :exp-boost="4.0" :vanity-items-amount="40"/>
    <subscription icon="fa-bow-arrow" name="Redstone" color="#bc2b20" :exp-boost="4.0" :vanity-items-amount="40"/>
    <subscription icon="fa-sparkles" name="Lapis" color="#253db7" :exp-boost="4.0" :vanity-items-amount="40"/>
  </div>

  <div id="loot-boxes">
    <h2 id="loot-boxes-title">Loot boxes</h2>
    <loot-box name="Obsidian Box" color="#3d1b37" :items-amount="70"/>
    <loot-box name="Gold Box" color="#e8b628" :items-amount="60"/>
    <loot-box name="iron Box" color="#9e9e9e" :items-amount="30"/>
    <loot-box name="Copper Box" color="#e5890e" :items-amount="15"/>
  </div>

  <div id="big-footer">
    <div id="big-footer-inner">

      <div id="footer-mistral-info">
        <div class="footer-item-inner">
          <img src="../assets/Mistral-dark@0.5x.png" alt="mistral logo dark"/>
          <p>The home to get your in-game goodies and perks for the Mistral network!</p>
        </div>
      </div>

      <div class="footer-links">
        <div class="footer-item-inner">
<!--          <h2>Links</h2>-->
<!--          <p>Search</p>-->
<!--          <p>About</p>-->
<!--          <p>Contact us</p>-->
<!--          <p>Terms & conditions</p>-->
        </div>
      </div>

      <div class="footer-links">
        <div class="footer-item-inner">
<!--          <h2>Links</h2>-->
<!--          <p>My profile</p>-->
<!--          <p>My Orders</p>-->
<!--          <p>Contact us</p>-->
<!--          <p>Privacy</p>-->
        </div>
      </div>

      <div id="footer-socials">
        <div class="footer-item-inner">
          <h2>Socials</h2>
          <div id="social-inner">
            <div class="social">
              <i class="fab fa-facebook"></i>
            </div>
            <div class="social">
              <i class="fab fa-instagram"></i>
            </div>
            <div class="social">
              <i class="fab fa-twitter"></i>
            </div>
            <div class="social">
              <i class="fab fa-youtube"></i>
            </div>
            <div class="social">
              <i class="fab fa-patreon"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="small-footer">
    <p>Copyright 2021 // Mistral Network</p>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import Subscription from "@/components/Subscription.vue";
import LootBox from "@/components/LootBox.vue";

export default defineComponent({
  name: 'Home',
  components: {LootBox, Subscription},
  data() {
    return {
      modalOpen: false
    }
  }
});
</script>


<style lang="scss" scoped>


#header {
  width: 100%;
  height: 900px;
  overflow: hidden;
  background-image: url("../assets/header.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;

  #header-overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.50);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    #title {
      margin: 0;
    }

    #subtitle {
      font-size: 2.3em;
      font-weight: 100;
      letter-spacing: 0.04em;
    }
  }
}

#info-block {
  width: 100%;
  display: flex;
  margin: 100px 0;

  .block {
    width: 50%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .block-inner {
      width: 70%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        font-size: 1.3em
      }
    }

    img {
      width: 70%;
    }
  }
}


#subscriptions {
  background-color: #282627;
  width: 100%;
  padding: 50px;
  display: flex;
  flex-wrap: wrap;

  #subscriptions-title {
    width: 100%;
    color: white;
    text-align: center;
    margin-bottom: 30px;
  }
}

#loot-boxes {
  background-color: #232221;
  width: 100%;
  padding: 50px;
  display: flex;
  flex-wrap: wrap;

  #loot-boxes-title {
    width: 100%;
    color: white;
    text-align: center;
    margin-bottom: 30px;
  }
}

#big-footer {
  display: flex;
  width: 100%;
  background-color: #143d59;
  color: white;
  justify-content: center;

  #big-footer-inner {
    width: 70%;
    display: flex;


    .footer-item-inner {
      padding: 40px;
      position: relative;
      p {
        font-family: "Bebas Neue", cursive;
        font-size: 1.3em;
      }

      h2 {
        font-size: 2em;
        color: #f5b41a;
        margin-top: 140px;
      }
    }

    #footer-mistral-info {
      width: 40%;

      img {
        width: 350px;
        margin-top: 25px;
      }

      p {
        margin-top: 10px;
      }
    }

    .footer-links {
      width: 20%;
    }


    #footer-socials {
      width: 20%;


      #social-inner {
        display: flex;
        position: absolute;

        justify-content: space-around;
        width: 75%;

        .social {
          width: 30px;
          height: 30px;
          color: #f5b41a;
          background-color: white;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

        }
      }
    }
  }
}

#small-footer {
  width: 100%;
  background-color: #103549;
  font-family: "Bebas Neue", cursive;
  color: white;
  padding: 20px;
  font-size: 1.5em;
  display: flex;
  justify-content: center;

  p {
    width: 70%;
    padding-left: 20px;
  }
}

</style>