
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BasketItem',
  props: {
    itemName: {
      type: String,
      required: true
    },
    itemImageURL: {
      type: String,
      required: true
    },
    itemsAmount: {
      type: Number,
      required: true
    },
    price: {
      type: Number,
      required: true
    },
  },
});
