<template>
  <div class="lootbox-container">
    <div class="lootbox">
      <div class="lootbox-image">
        <i class="fas fa-treasure-chest"  :style="colorStyle"></i>
      </div>
      <div id="title">
        {{ name }}
      </div>
      <div class="lootbox-perks">
        <p class="lootbox-perk">+ <i class="yellow">{{ itemsAmount }}</i> RANDOM ITEMS</p>
      </div>
      <button class="buy-button" type="button">Add to basket</button>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'LootBox',
  components: {},
  props: {
    name: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    itemsAmount: {
      type: Number,
      required: true
    },
  },
  computed: {
    colorStyle() {
      return {
        "color": this.color
      }
    }
  }
});
</script>


<style lang="scss" scoped>
.lootbox-container {
  width: 25%;
  display: flex;
  justify-content: center;

  .lootbox {
    display: flex;
    align-items: center;
    flex-direction: column;

    .lootbox-image {
      width: 220px;
      height: 220px;
      border-radius: 10%;
      background-color: #fcfcfc;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 6.5em;
    }

    #title {
      font-family: "Bebas Neue", cursive;
      color: white;
      font-size: 2em;
    }

    .lootbox-perks {
      color: white;
      //width: 100%;

      .lootbox-perk {
        font-family: "Bebas Neue", cursive;
        //letter-spacing: 0.1em;
        font-size: 1.5em;

        .yellow {
          font-style: normal;
          color: #f5b41a;
        }

      }
    }

    .buy-button {
      align-self: center;
      margin-top: 20px;
      //align-self: start;
      width: fit-content;
      border: none;
      color: #143d59;
      font-family: "Bebas Neue", cursive;
      font-size: 2em;
    }

    .lootbox-perk, .buy-button {
      //margin-left: 30px;

    }
  }
}
</style>